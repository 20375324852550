<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
    <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
        <g>
            <circle class="st0" cx="125" cy="125" r="124.5"/>
            <g>
                <g>
                    <g>
                        <g>
                            <path id="XMLID_4_" class="st1" d="M189,168.8H61c-1.6,0-2.8-1.3-2.8-2.8V84.1c0-1.6,1.3-2.8,2.8-2.8h128        c1.6,0,2.8,1.3,2.8,2.8V166C191.8,167.5,190.5,168.8,189,168.8z"/>
                        </g>
                    </g>
                </g>
                <rect x="56.8" y="94.9" class="st0" width="136.8" height="17.4"/>
                <g>
                    <path class="st2" d="M111.2,143.7H69.9c-0.3,0-0.6-0.3-0.6-0.6v-7.2c0-0.3,0.3-0.6,0.6-0.6h41.3c0.3,0,0.6,0.3,0.6,0.6v7.2      C111.8,143.4,111.6,143.7,111.2,143.7z"/>
                    <path class="st2" d="M132.9,158.5h-63c-0.3,0-0.6-0.3-0.6-0.6v-7.2c0-0.3,0.3-0.6,0.6-0.6h63c0.3,0,0.6,0.3,0.6,0.6v7.2      C133.5,158.2,133.2,158.5,132.9,158.5z"/>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'PaymentMethods',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st0{fill:#EFECFF;}
	.st1{fill:#9C79EF;}
	.st2{fill:#EDE3FB;}
</style>